/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllImportWarehouses({ payload }) {
  try {
    const response = yield call(
      typeof payload === 'string'
        ? () => axiosMicro.get(`/imports?${payload}`)
        : () => axiosMicro.get('/imports', { params: payload }),
    );
    yield put(Actions.getAllImportWarehousesSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getAllImportWarehousesFailure(messages));
    }
  }
}

function* createImportWarehouse({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/imports', payload));
    yield put(Actions.createImportWarehouseSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.createImportWarehouseFailure(messages));
    }
  }
}

function* updateImportWarehouse({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/imports/${payload.id}`, payload.body));
    yield put(Actions.updateImportWarehouseSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.updateImportWarehouseFailure(messages));
    }
  }
}

function* cancelImportWarehouse({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/imports/${payload}/cancel`));
    yield put(Actions.cancelImportWarehouseSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.cancelImportWarehouseFailure(messages));
    }
  }
}

function* deleteImportWarehouse({ payload }) {
  try {
    yield call(() => axiosMicro.delete(`/imports/${payload}`));
    yield put(Actions.deleteImportWarehouseSuccess(payload));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.deleteImportWarehouseFailure(messages));
    }
  }
}

function* considerImportWarehouse({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/imports/${payload.id}/status`, payload.body));
    yield put(Actions.considerImportWarehouseSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.considerImportWarehouseFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllImportWarehousesRequest, getAllImportWarehouses);
  yield takeLatest(Actions.createImportWarehouseRequest, createImportWarehouse);
  yield takeLatest(Actions.updateImportWarehouseRequest, updateImportWarehouse);
  yield takeLatest(Actions.cancelImportWarehouseRequest, cancelImportWarehouse);
  yield takeLatest(Actions.deleteImportWarehouseRequest, deleteImportWarehouse);
  yield takeLatest(Actions.considerImportWarehouseRequest, considerImportWarehouse);
}
